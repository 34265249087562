<template>
<div class="certForm"  v-loading="loading">
    <div class="main">
      <div class="list" v-if="list.length">
        <div class="listItem" v-for="(item,index) in list">
            <div class="title">
               <p >{{item.cert_name}}</p>
               {{item.day_time}}
            </div>
            <cert-save :lessonNm="item.cert_name" :certInfoItem="item" :userInfoItem="user_list" :index="index"  type="true" v-if="item.discipline_id != 1228"></cert-save>
            <cert-save-t-j :lessonNm="item.cert_name" :certInfoItem="item" :userInfoItem="user_list" :index="index"  type="true" v-else></cert-save-t-j>
         </div>
      </div>
      <div class="noData" v-else-if="!loading">
        <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/tuoyuEmpty.jpg"/>
        <p>未查询到相关证书</p>
      </div>
    </div>
</div>
</template>

<script>
  import CertSave from './CertSave.vue';
  import CertSaveTJ from './CertSaveTJ.vue';
export default {
  components: {CertSave, CertSaveTJ},
  name: "cert",
  data() {
    
    return {
      loading: false,
      list:[],
      user_list:null,
    }
  },
  mounted() {
    this.loading = true
    this.$request.certList({flat_id:this.plat}).then(res=>{
      this.loading = false
      if(res.code==0){
        this.list = res.data.cret_list
        this.user_list = res.data.user_list
      }else{
        this.$toast(res.msg)
      }
    })
  },
  destroyed() {},
  computed: {
    plat() {
      return this.$store.state.plat_id
    }
  },
  methods: {
    

   
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    next()
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }

};
</script>

<style lang="scss" scoped>
.certForm {
  min-height: calc(100vh - 230px);
  .main{
    width: 100%;
    .list{
      overflow: hidden;
      .listItem{
        width: 390px;
        float: left;
        margin-right: 15px;
        margin-bottom: 30px;
        &:nth-child(3n+3){
          margin-right:0
        }
        .title{
            display: flex;
            color: #666;
            font-size: 20px;
            align-items: flex-end;
            padding-bottom: 24px;
            p{
              flex: 1;
              color: #333333;
              font-size: 20px;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
      };
    }
    .noData{
        text-align: center;
        font-size: 30px;
        color: #9FA2B3;
        padding-top: 250px;
        img{
          width: 200px;
          height: 162px;
          margin-bottom: 50px;
        }
      }
    }

}
</style>

<style lang="scss">

</style>
